import React from 'react';
import ReDialog from '@components/ReDialog';
import theme from '@styles/theme';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Typography, Button } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import InputRating from '@pages/feedback/InputRating';
import { useAuthContext } from '@context/AuthContext';
import { useHistory } from 'react-router-dom';
import LoadingModal from '@components/LoadingModal';
import { getColorApp } from '@data/localStorageGetter';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import useFeedback from '@hooks/useFeedback';
import { styled } from '@mui/system';
import { useStoreAppColor } from '@state/useStoreAppColor';
const ModalOverlay = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflowY: 'auto',
}));
const useStyles = makeStyles({
  innerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(13),
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  removeButton: {
    display: 'flex',
    flexDirection: 'column',
  },

  formFilledCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
});

const FeedbackPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getAuthState } = useAuthContext();
  const history = useHistory();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const [listDataFeedback, isFilledFeedback, handleRating, handleBtnSend] =
    useFeedback();

  return (
    <ModalOverlay>
      <ModalContainer>
        <Box className={classes.innerTitle}>
          <Box
            display="flex"
            flexDirection="row"
            boxShadow={3}
            bgcolor={appColor.primaryThemeColor}
            color={appColor.secondaryFontColor}
            style={{
              alignItems: 'center',
              textAlign: 'center',
              height: theme.spacing(13),
              width: '100%',
            }}>
            <ButtonBase
              id="backToHome"
              className={classes.iconButton}
              onClick={() => {
                sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
                history.replace(URLS.MENU_CATALOGUE);
              }}>
              <ChevronLeft style={{ width: 32, height: 32 }} />
            </ButtonBase>
            <Typography className={classes.title} variant="h6">
              <b>{t('feedback').toUpperCase()}</b>
            </Typography>
            <Box style={{ width: 65 }} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            paddingTop: 2,
          }}>
          {isFilledFeedback ? (
            <Box className={classes.formFilledCover} sx={{ flex: 1 }}>
              <Typography variant="h5">{t('feedbackThanks')}</Typography>
              <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                {t('feedbackThanksBottom')}
              </Typography>
            </Box>
          ) : (
            <Box sx={{ flex: 1, overflowY: 'auto' }}>
              {listDataFeedback &&
                Array.isArray(listDataFeedback) &&
                listDataFeedback.length > 0 &&
                listDataFeedback.map((item, index) => (
                  <Box display="flex" flexDirection="column" key={index}>
                    <InputRating
                      index={index}
                      data={item}
                      onChange={handleRating}
                    />
                  </Box>
                ))}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  padding: 2,
                  position: 'absolute',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Button
                  onClick={() => {
                    handleBtnSend();
                  }}
                  sx={{
                    color: getColorApp()?.secondaryFontColor,
                    backgroundColor: getColorApp()?.primaryThemeColor,
                    width: '100%',
                    '&:hover': {
                      backgroundColor: getColorApp()?.primaryThemeColor,
                    },
                  }}>
                  {t('send')}
                </Button>
              </Box>
            </Box>
          )}

          {getAuthState('loadingModal') && <LoadingModal />}
        </Box>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default FeedbackPage;
