import React, { useState } from 'react';
import ReDialog from '@components/ReDialog';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@context/AuthContext';
import { useCartContext } from '@context/CartContext';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import URLS from '@/URLS';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreSettings } from '@state/useStoreSettings';
import ReDropDownFont from './ReDropDownFont';
import { Grid, ListItemButton, ListItem } from '@mui/material';
import { useGeneralContext } from '@context/GeneralContext';
import { sendPostMessageToParent } from '@functions/trafficController';
import ReFontSlider from './ReFontSlider';

const useStyles = makeStyles({
  paper: {
    zIndex: `1310 !important`,
  },
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1299,
  },
});

const ToggleLang = () => {
  const { i18n } = useTranslation();

  const handleChangeLang = (e, value) => {
    i18n.changeLanguage(value);
  };

  return (
    <ToggleButtonGroup
      style={{ height: 20 }}
      value={i18n.language}
      exclusive
      onChange={handleChangeLang}>
      <ToggleButton value="id">ID</ToggleButton>
      <ToggleButton value="en">EN</ToggleButton>
    </ToggleButtonGroup>
  );
};

const ToggleOpen = () => {
  const { isOpen, setOpen } = useAuthContext();
  const [stateOpen, setStateOpen] = useState(isOpen());

  const handleChangeLang = (e, value) => {
    setStateOpen(JSON.parse(value));
    setOpen(JSON.parse(value));
  };

  return (
    <ToggleButtonGroup
      style={{ height: 20 }}
      value={stateOpen.toString()}
      exclusive
      onChange={handleChangeLang}>
      <ToggleButton value="true">True</ToggleButton>
      <ToggleButton value="false">False</ToggleButton>
    </ToggleButtonGroup>
  );
};

const ToggleViewBillSetting = () => {
  const { setOrderMethod } = useAuthContext();
  const { getOrderMethod } = useStoreSettings();
  const [viewBill, setViewBill] = useState(getOrderMethod());

  const handleChange = (e) => {
    setOrderMethod(e.currentTarget.value);
    setViewBill(e.currentTarget.value);
  };

  return (
    <ToggleButtonGroup
      style={{ height: 20 }}
      value={viewBill.toString()}
      exclusive
      onChange={handleChange}>
      <ToggleButton value="normal_order">Table</ToggleButton>
      <ToggleButton value="payment_order">Personal</ToggleButton>
    </ToggleButtonGroup>
  );
};

const DevelopmentPanel = ({ openDevPanel }) => {
  const classes = useStyles();
  const history = useHistory();
  const [mockQty, setMockQty] = useState(1);
  const { clearAllStorage, toggleDevPanel } = useAuthContext();
  const { addToCart, handleOpenCheckoutPage } = useCartContext();
  const { getProductList } = useStoreProduct();
  const { closeDrawer } = useGeneralContext();

  const handleMockOrder = () => {
    const getRandomNumber = (max) => {
      return Math.floor(Math.random() * Math.floor(max));
    };

    let selectedProducts = [];
    for (let i = 0; i < mockQty; i++) {
      let mockedProductCategory =
        getProductList()[getRandomNumber(getProductList().length)];
      let mockedProduct =
        mockedProductCategory.products[
          getRandomNumber(mockedProductCategory.products.length)
        ];

      mockedProduct.quantity = getRandomNumber(4) || 1;
      mockedProduct.additional_request = `Mock description (${i + 1})`;
      mockedProduct.timestamp = new Date().getTime() + getRandomNumber(3);

      if (mockedProduct.prep_group.length > 0) {
        mockedProduct.prep_group.map((group, groupIndex) => {
          group.prep_item.map((item, itemIndex) => {
            if (itemIndex < group.choose.max) {
              mockedProduct.prep_group[groupIndex].prep_item[
                itemIndex
              ].checked = true;
            }
            return '';
          });
          return '';
        });
      }

      selectedProducts.push(mockedProduct);
    }

    // MASUK KAN DATA KECART SATU PER SATU DENGAN DELAY 2 DETIK, KARENA LOCAL STORAGE GAK BISA SECEPAT MILI SECOND
    selectedProducts.forEach((item, index) => {
      setTimeout(() => {
        addToCart(item, true);
      }, index * 2000);
    });

    handleOpenCheckoutPage();
  };

  return (
    <div className={classes.container}>
      {openDevPanel && (
        <ReDialog
          title="DEVELOPMENT PANEL"
          dialogStyle={classes.paper}
          content={
            <Grid container direction="column">
              {/* Login Time */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: '8px 16px',
                }}>
                <Grid item>
                  {`login time: ${moment(
                    +JSON.parse(localStorage.getItem('creds'))['login-stamp'],
                  ).format('DD/MM/YYYY HH:mm:ss')}`}
                </Grid>
              </Grid>

              {/* Close Dev Panel Button */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItemButton
                  onClick={toggleDevPanel}
                  sx={{
                    backgroundColor: 'red',
                    color: 'white',
                    border: '2px solid white', // Adds a white border
                    borderRadius: '8px', // Optional: Rounds the corners
                    '&:hover': {
                      backgroundColor: 'darkred', // Optional: darker shade on hover
                      borderColor: 'lightgray', // Optional: changes border color on hover
                    },
                  }}>
                  Close Dev Panel
                </ListItemButton>
              </Grid>

              {/* Review Button */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItemButton
                  onClick={() => {
                    closeDrawer();
                    toggleDevPanel();
                    sendPostMessageToParent('path', URLS.REVIEW);
                    history.push(URLS.REVIEW);
                  }}>
                  Review
                </ListItemButton>
              </Grid>

              {/* Feedback Button */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItemButton
                  onClick={() => {
                    sendPostMessageToParent('path', URLS.FEEDBACK);
                    history.push(URLS.FEEDBACK);
                  }}>
                  Feedback
                </ListItemButton>
              </Grid>

              {/* Suggestion Button */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItemButton
                  onClick={() => {
                    sendPostMessageToParent('path', URLS.SUGGESTION);
                    history.push(URLS.SUGGESTION);
                  }}>
                  Suggestion
                </ListItemButton>
              </Grid>

              {/* Suggestion Button */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItemButton
                  onClick={() => {
                    sendPostMessageToParent('path', URLS.REWARDS);
                    history.push(URLS.REWARDS);
                  }}>
                  PROMO
                </ListItemButton>
              </Grid>

              {/* Font Settings */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItem>
                  <Grid sx={{ display: 'flex', width: '100%' }}>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '50%',
                      }}>
                      Change Font
                    </Grid>
                    <Grid item sx={{ width: '50%', textAlign: 'right' }}>
                      <ReDropDownFont />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>

              {/* Font Size Settings */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                }}>
                <ListItem>
                  <Grid sx={{ display: 'flex', width: '100%' }}>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '50%',
                      }}>
                      Font Scale
                    </Grid>
                    <Grid item sx={{ width: '50%', textAlign: 'right' }}>
                      <ReFontSlider />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>

              {/* Language Toggle */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: '8px 16px',
                }}>
                <Grid item>Language</Grid>
                <Grid item>
                  <ToggleLang />
                </Grid>
              </Grid>

              {/* Toggle Open */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: '8px 16px',
                }}>
                <Grid item>isOpen</Grid>
                <Grid item>
                  <ToggleOpen />
                </Grid>
              </Grid>

              {/* View Bill Settings */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: '8px 16px',
                }}>
                <Grid item>ViewBill Setting</Grid>
                <Grid item>
                  <ToggleViewBillSetting />
                </Grid>
              </Grid>

              {/* Mock Order */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: '8px 16px',
                }}>
                <Grid item>Mock Order</Grid>
                <Grid item>
                  <input
                    type="number"
                    sx={{ width: 50 }}
                    value={mockQty}
                    onChange={(event) => {
                      setMockQty(
                        event.target.value > 10 ? 10 : event.target.value,
                      );
                    }}
                  />
                  <button onClick={handleMockOrder}>ORDER</button>
                </Grid>
              </Grid>

              {/* Logout */}
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: '8px 16px',
                }}>
                <Grid item>NoClearToken</Grid>
                <Grid item>
                  <button
                    onClick={() => {
                      clearAllStorage('partial');
                      history.replace(URLS.LOGIN);
                    }}>
                    Logout
                  </button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      )}
    </div>
  );
};

export default DevelopmentPanel;
