import React from 'react';
import { Grid, Typography, ButtonBase, Box, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import ReLazyImage from '@components/ReLazyImage';
import { convertCurrency } from '@functions/convertNumber';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import { getImageThumnailGrid } from '@functions/imageOptimization';
import icon from '@assets/images/icon_full.svg';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from 'use-window-size-v2';
import ReLegend from './ReLegend';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { isProductActive } from '@functions/checkProductActive';
import { NoFood, AccessTime } from '@mui/icons-material';
import { useStoreSellTime } from '@state/useStoreSellTime';

// NUMBER OF COLUMN
const cols = 2;

const useStyles = makeStyles({
  panelContainer: {
    width: '100%',
    margin: 0,
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  panelSplitter: {
    width: `calc(100% / ${cols})`,
    paddingTop: `${theme.spacing(2)}px`,
    paddingBottom: `${theme.spacing(2)}px`,
  },
  panelItem: {
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  borderRadius: {
    borderRadius: 10,
  },
  halal: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) 0 0`,
  },

  beef: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -20px 0`,
  },
  chicken: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -60px 0`,
  },
  pork: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -80px 0`,
  },
  fish: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -120px 0`,
  },
  thumbs_up_like: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -140px 0`,
  },
  spicy: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -100px 0`,
  },
  chef_recommended: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -160px 0`,
  },
  vegetarian: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -180px 0`,
  },
  lamb: {
    width: '20px',
    height: '20px',
    marginRight: theme.spacing(1),
    background: `url(${icon}) -40px 0`,
  },
  paddingText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  positionNutriGrade: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  noPromo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '90%',
    maxWidth: theme.containerWidth,
  },
});

const PanelComponent = ({
  classes,
  t,
  getSellBandId,
  getSetting,
  data,
  width,
  onClickItem,
}) => {
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  return (
    <Box
      boxShadow={3}
      className={classes.borderRadius}
      sx={{
        opacity: isProductActive(data) ? 1 : 0.7,
        width: '100%',
        height: '100%',
      }}>
      <ButtonBase
        disabled={!isProductActive(data)}
        focusRipple
        sx={{ width: '100%', height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          disabled={!isProductActive(data)}
          onClick={() => {
            onClickItem(data);
          }}
          className={`${classes.panelItem} ${
            !isProductActive(data) && classes.disablePanel
          }`}
          sx={{
            background: appColor.backgroundProduct,
            width: '100%',
            height: '100%',
          }}>
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="center"
            position="relative">
            <ReLazyImage
              url={getImageThumnailGrid(
                data.url,
                width / 2 - 12,
                width / 2 - 12,
              )}
              style={{
                width: width / 2 - 12,
                height: width / 2 - 12,
              }}
              index="product"
            />
            {urlNutriGrade(data, 'v') && (
              <Box className={`${classes.positionNutriGrade} nutri-grade`}>
                <ReLazyImage
                  url={urlNutriGrade(data, 'v')}
                  style={{ width: 40 }}
                  index="nutri-grade-product"
                />
              </Box>
            )}
          </Box>
          <Box
            className={classes.paddingText}
            display="flex"
            flexDirection="column"
            width={1}>
            <Typography
              className="clampOnLine2"
              variant="body1"
              sx={{
                height: 50,
                textAlign: 'left',
                lineHeight: 1,
                marginTop: theme.spacing(1),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                letterSpacing: 0,
                fontSize: '0.875rem',
              }}>
              {data.plu_name}
            </Typography>
            <Box display="flex" flexDirection="row">
              <Box
                component="div"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={12}
                whiteSpace="noWrap">
                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                  {isProductActive(data)
                    ? convertCurrency(
                        getPriceBySellBand(data, getSellBandId(), true),
                      )
                    : getSetting() && getSetting().soldOutText
                    ? getSetting().soldOutText
                    : t('outOfStock')}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row-reverse" flex="1">
                {data.legend && <ReLegend legend={data.legend} type="grid" />}
              </Box>
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
};

const ReGridviewPromo = ({ dataSet = {}, count = 0, stamp = '' }) => {
  const classes = useStyles();
  const { addToCart, setCartModal, setContextState } = useCartContext();
  const { openSuccessSnackBar, getSellBandId, showRewardsAlertDialog } =
    useGeneralContext();
  const { getSetting } = useAuthContext();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const { sellTimeProducts } = useStoreSellTime((state) => ({
    sellTimeProducts: state.sellTimeProducts,
  }));
  const handleClickItem = (data) => {
    data.timestamp = new Date().getTime();
    data.quantity = 1;
    data.group = [];
    let newData = {
      ...data,
      is_have_suggestion: false, // TODO : jika mengaktifkan suggestion di promo, perbaiki di sini.
      is_have_promo: false,
      promoName: dataSet?.promotion_name,
      promoFrom: stamp,
    };
    if (count === 0) {
      showRewardsAlertDialog(true);
    } else {
      if (
        getSetting() &&
        getSetting().isDirectOrder &&
        !data.is_variant &&
        data.modifier_group.length < 1 &&
        !data.is_have_group
      ) {
        addToCart(newData);
        openSuccessSnackBar(
          `1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`,
        );
      } else {
        setContextState({ isRewardsPage: true });
        setCartModal(true, newData);
      }
    }
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.panelContainer}>
        {Array.isArray(dataSet.products) && dataSet.products.length > 0 ? (
          dataSet.products.map((dataItem, index) => {
            const isLastRow =
              Math.floor(index / 2) ===
              Math.floor((dataSet.products.length - 1) / 2);

            const matchingSellTimeProduct = sellTimeProducts.find(
              (prod) => prod.uuid_product === dataItem.uuid_product,
            );

            // Determine the is_active value. You can provide a default if not found.
            const isActive = matchingSellTimeProduct
              ? matchingSellTimeProduct.is_active
              : dataItem.is_active;

            const data = { ...dataItem, is_active: isActive };
            return (
              <Grid
                id={`item-${index}`}
                item
                key={index}
                className={`${classes.panelSplitter} productItem`}
                sx={
                  index % 2 === 1
                    ? {
                        padding: '5px',
                        marginBottom: isLastRow ? '5px' : '0px',
                        height: '100%',
                      }
                    : {
                        padding: '5px',
                        marginBottom: isLastRow ? '5px' : '0px',
                        height: '100%',
                      }
                }>
                <PanelComponent
                  classes={classes}
                  t={t}
                  getSellBandId={getSellBandId}
                  getSetting={getSetting}
                  data={data}
                  width={
                    width > theme.containerWidth ? theme.containerWidth : width
                  }
                  onClickItem={(data, type) =>
                    handleClickItem(
                      { ...data, signature_token: uuidv4() },
                      type,
                    )
                  }
                />
              </Grid>
            );
          })
        ) : (
          <Grid className={classes.noPromo}>
            <NoFood color="disabled" sx={{ fontSize: theme.spacing(14) }} />
            <Typography
              variant="h6"
              sx={{ fontWeight: '600', marginBottom: '8px' }}>
              No Promotion Available
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary',
                textAlign: 'center',
                marginBottom: '24px',
              }}>
              Currently there are not active promotions
            </Typography>
            <Alert
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: appColor.primaryThemeColor,
                borderColor: appColor.primaryThemeColor,
                margin: 'auto',
                width: '224px',
              }}
              icon={<AccessTime fontSize="inherit" />}
              severity="warning">
              Selling time has ended
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ReGridviewPromo;
