import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGeneralContext } from '@context/GeneralContext';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreAppColor } from '@state/useStoreAppColor';
import theme from '@styles/theme';
import { useStoreToggleDrawer } from '@state/useStoreToggleDrawer';
import { useStoreCategory } from '@state/useStoreCategory';

const useStyles = makeStyles({
  nested: {
    paddingLeft: theme.spacing(8),
    cursor: 'pointer',
  },
  panel: {
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important',
    margin: '0 !important',
    '&::before': {
      backgroundColor: 'transparent !important',
    },
  },
  panelSummary: {
    minHeight: 'auto !important', // Let content dictate the height
    margin: '0 !important',
    paddingLeft: '16px !important',
    // Also remove default content margin if needed:
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },
});

const CategoryList = ({}) => {
  // key state to force re-render when font change detected

  const [reRenderKey, setReRenderKey] = useState(0);

  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);
  const { closeDrawer } = useGeneralContext();
  const { setActiveSection, setTabScrolling } = useStoreCategory((state) => ({
    setActiveSection: state.setActiveSection,
    setTabScrolling: state.setTabScrolling,
  }));
  const { getSetting } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
  }));
  // const { onClickTabCategory } = useAuthContext()
  const { categoryList } = useStoreToggleDrawer((state) => ({
    categoryList: state.categoryList,
  }));

  const handleItemClick = (category, uuid, index) => {
    if (getSetting().isInfinityScroll) {
      setActiveSection(index);
      setTabScrolling(true);
      closeDrawer();
    } else {
      setActiveSection(index);
      closeDrawer();
    }
  };

  // Detect Font Change and Re-Render the list
  useEffect(() => {
    const handleThemeChange = () => {
      setReRenderKey((prev) => prev + 1);
    };

    window.addEventListener('themeChange', handleThemeChange);
    return () => window.removeEventListener('themeChange', handleThemeChange);
  });

  return (
    <>
      {(categoryList || []).map((category, index) => (
        <Accordion
          className={classes.panel}
          key={category.uuid_product_category}>
          <AccordionSummary
            className={classes.panelSummary}
            expandIcon={
              category?.categories?.length > 0 && (
                <ExpandMoreIcon style={{ color: 'black' }} />
              )
            }
            sx={{ paddingLeft: 4 }}>
            <ListItemButton
              sx={{ paddingLeft: 0, alignItems: 'center', height: 'auto' }}
              id={`item-${index}`}
              key={index}
              onClick={() => {
                if (category?.categories?.length < 1) {
                  handleItemClick(
                    category,
                    category.uuid_product_category,
                    category.index,
                  );
                }
              }}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'normal', // Allow natural wrapping
                      wordBreak: 'break-word', // Break long words if needed
                    }}>
                    {category.category_name.toUpperCase()}
                  </Typography>
                }
              />
            </ListItemButton>
          </AccordionSummary>
          {Array.isArray(category.categories) &&
            category.categories.map((subCategory) => (
              <ListItemButton
                sx={{
                  paddingLeft: theme.spacing(8),
                  py: 1, // vertical padding for consistency
                  alignItems: 'center',
                }}
                key={subCategory.uuid_product_category}
                onClick={() =>
                  handleItemClick(
                    subCategory,
                    subCategory.uuid_product_category,
                    subCategory.index,
                  )
                }>
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}>
                      {subCategory.category_name.toUpperCase()}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
        </Accordion>
      ))}
    </>
  );
};

export default CategoryList;
