import React, { useEffect, useState } from 'react';
import ReGridviewPanelTab from '@components/ReGridviewPanelTab';
import ReListViewPanelTab from '@components/ReListViewPanelTab';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreCategory } from '@state/useStoreCategory';

const ItemProductTab = () => {
  const { flatProducts } = useStoreProduct((state) => ({
    flatProducts: state.flatProducts,
  }));
  const [selectedProduct, setSelectedProduct] = useState();

  const { activeSection } = useStoreCategory((state) => ({
    activeSection: state.activeSection,
  }));

  useEffect(() => {
    const tabIndex = activeSection;
    setSelectedProduct(flatProducts[tabIndex]);
  }, [activeSection]);

  // key used for render item from the top position
  return (
    <>
      {selectedProduct &&
        (selectedProduct.menu_type === 'grid' ? (
          <ReGridviewPanelTab category={selectedProduct} key={activeSection} />
        ) : (
          <ReListViewPanelTab category={selectedProduct} key={activeSection} />
        ))}
    </>
  );
};

export default ItemProductTab;
