import React from 'react';
import { Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import theme from '@styles/theme';

const useStyle = makeStyles({
  widthContainer: {
    width: theme.containerWidth - 20,
    maxWidth: theme.containerWidth,
  },
});

const ReSnackbar = ({ show, type, message, onClose }) => {
  const classes = useStyle();

  return (
    <Snackbar
      id="snackbar"
      open={show}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={type === 'success' ? 1500 : 5000}
      onClose={(event, trigger) => {
        if (trigger !== 'clickaway') {
          onClose();
        }
      }}
      key={`${type}${message}`}>
      <Alert
        severity={type}
        variant="filled"
        className={classes.widthContainer}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
            style={{ marginLeft: 50 }}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }>
        {message}
      </Alert>
    </Snackbar>
  );
};
export default ReSnackbar;
