import React from 'react';
import { ChevronLeft, Refresh, HistoryRounded } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Grid, Box, ButtonBase, Typography, Fab } from '@mui/material';
import Alert from '@mui/lab/Alert';
import { useTranslation } from 'react-i18next';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { styled } from '@mui/system';
import BillNoTransaction from './BillNoTransaction';
import BillData from './BillData';

const ModalOverlay = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflowY: 'hidden',
  fontSize: 14,
  lineHeight: 1.5,
}));

const useStyles = makeStyles((theme) => ({
  innerTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  fab: {
    position: 'absolute !important',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
}));

// EXPECTED PROPS 30/09/2022
// onBack*
// title*
// billData*
// table*
// outlet*
// salesType*
// disableRefresh*
// onClickRefresh*
// onConfirm*
// loading*
// billDiscount
// billSubTotal
// billGrandTotal
// billTaxes
// warningMessage
// showHistory

const ReBillLayout = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const BillInformation = () =>
    props.showViewBill ? (
      <>
        <Grid
          container
          key={props.outlet}
          sx={{
            display: 'block',
            position: 'relative', // This makes the container the reference for absolute positioning.
            padding: '2.2rem 1.5rem 0 1.5rem',
            overflowY: 'auto',
            height: '92vh',
          }}>
          {props.loadingUpdate && (
            <div
              style={{
                position: 'absolute',
                top: '1rem', // adjust as needed
                left: 0,
                right: 0,
                textAlign: 'center',
                zIndex: 10, // make sure it appears above the bill data
                pointerEvents: 'none', // so underlying content is not blocked (optional)
              }}>
              Loading...
            </div>
          )}
          {props.warningMessage && (
            <div
              style={{
                width: '100%',
                '& > * + *': { marginTop: theme.spacing(2) },
              }}>
              <Alert severity="warning">{t('transaction_split_info')}</Alert>
            </div>
          )}
          {props.billData && Object.keys(props.billData).length !== 0 ? (
            <BillData props={props} />
          ) : (
            <BillNoTransaction onClickRefresh={props.onClickRefresh} />
          )}
        </Grid>
      </>
    ) : null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <Box className={classes.innerTitle}>
          <Box
            display="flex"
            flexDirection="row"
            boxShadow={3}
            style={{
              alignItems: 'center',
              textAlign: 'center',
              height: theme.spacing(13),
              width: '100%',
            }}>
            <ButtonBase
              id={'backButton'}
              className={classes.iconButton}
              onClick={() => {
                if (props.onBack) {
                  sendPostMessageToParent('path', props.onBack);
                  history.replace(props.onBack);
                } else {
                  sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
                  history.replace(URLS.MENU_CATALOGUE);
                }
              }}>
              <ChevronLeft style={{ width: 32, height: 32 }} />
            </ButtonBase>
            <Typography className={classes.title} variant="h6">
              <b>{props.title}</b>
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.iconButton}
              style={{ paddingLeft: 0, paddingRight: theme.spacing(3) }}>
              <ButtonBase
                sx={{
                  color: props.disableRefresh
                    ? theme.palette.text.disabled
                    : 'inherit',
                }}
                onClick={() => {
                  props.onClickRefresh();
                }}
                disabled={props.disableRefresh}>
                <Refresh sx={{ width: 32, height: 32 }} />
              </ButtonBase>
            </Box>
          </Box>
        </Box>

        {props.showHistory ? (
          <Box>
            <Fab
              id="gotoHistoryBill"
              sx={{
                backgroundColor: appColor.secondaryThemeColor,
                color: appColor.secondaryFontColor,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: appColor.secondaryThemeColor,
                  boxShadow: 'none',
                },
              }}
              className={classes.fab}
              onClick={() => {
                sendPostMessageToParent('path', URLS.HISTORY_BILL);
                history.replace(URLS.HISTORY_BILL);
              }}>
              <HistoryRounded />
            </Fab>
          </Box>
        ) : (
          <>{props && <BillInformation />}</>
        )}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ReBillLayout;
